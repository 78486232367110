.lato {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.menlo {
  font-family: Menlo, Monaco, "Courier New", monospace;
}

.verdana {
  font-family: "Verdana", "Arial";
}
