.autocomplete-suggestions {
  text-align: left;
  cursor: default;
  background: #1f1f1f;
  border: 1px solid #353535;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.4);

  /* core styles should not be changed */
  position: absolute;
  display: none;
  z-index: 9999;
  max-height: 254px;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.autocomplete-suggestion {
  position: relative;
  padding: 0 0.6em;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.02em;
  color: #CACACA;
}

.autocomplete-suggestion b {
  font-weight: normal;
  color: #009AF5;
}

.autocomplete-suggestion.selected {
  background: #003048;
}
