.theme-oscuro {
  scrollbar-face-color: #646464;
  scrollbar-base-color: #646464;
  scrollbar-3dlight-color: #646464;
  scrollbar-highlight-color: #646464;
  scrollbar-track-color: #000;
  scrollbar-arrow-color: #000;
  scrollbar-shadow-color: #646464;
  scrollbar-dark-shadow-color: #646464;

  scrollbar-color: #646464 #1e1e1e;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  max-width: 10px;
  max-height: 10px;
}

::-webkit-scrollbar-button {
  background-color: #666;
}

::-webkit-scrollbar-track {
  background-color: #646464;
}

::-webkit-scrollbar-track-piece {
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background-color: black;
}

::-webkit-resizer {
  background-color: #666;
}
