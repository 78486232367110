.animacion-aparecer {
  animation-duration: 0.75s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animacion-bajar {
  animation: bajar 0.2s linear;
}
@keyframes bajar {
  from {
    transform: translateY(-25px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.animacion-aparecer {
  animation: aparecer 0.2s linear;
}
@keyframes aparecer {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.animar-width {
  transition: width 500ms ease;
}

@keyframes drop-fade-below {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes drop-fade-above {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.slide-fade {
  will-change: transform, opacity;
  &.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-in {
    animation: drop-fade-below 0.15s;
  }
  &.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-out {
    animation: drop-fade-below 0.15s reverse;
  }
  &.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-in {
    animation: drop-fade-above 0.15s;
  }
  &.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-out {
    animation: drop-fade-above 0.15s reverse;
  }
}
