input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 4px;
  border: none;
  border-radius: 3px;
  background-color: gray;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 18px;
  width: 12px;
  margin-top: -7px;
  border-radius: 1px;
  background: white;

  border: 1px solid #333434;
  border-radius: 4px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
}

input[type="range"] {
  /* fix for FF unable to apply focus style bug  */
  border: 0px solid white;
}

input[type="range"]::-moz-range-track {
  height: 4px;
  background: gray;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  border: none;
  height: 18px;
  width: 12px;
  margin-top: -7px;
  border-radius: 1px;
  background: white;

  border: 1px solid #333434;
  border-radius: 4px;
}

/*hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline: 1px solid transparent;
  outline-offset: -1px;
}

input[type="range"]:focus::-moz-range-track {
}
