.theme-oscuro {
  .input {
    letter-spacing: 0;
    border: 1px solid #333434;
    background-color: #454646;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.05);
    color: #c6c8c8;
    vertical-align: top;
    outline: 0;
  }

  .input:focus {
    background-color: #595b5b;
    color: #fff;
    border: 1px solid #0036ff;
    box-shadow: 0 0 0 2px #6fb5f1;
  }

  .selector-de-codigo {
    border: 1px solid #333434;
    background-color: #454646;
  }

  .select {
    color: #c6c8c8;
    border: 1px solid #333434;
    background-color: #454646;
    padding: 2px;
    outline: 0;
    height: 26px;
  }

  .select:focus {
    color: white;
  }

  .spinner-color-de-fondo {
    fill: white;
  }

  .spinner-color {
    fill: white;
  }

  .boton {
    color: #c6c8c8;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.69);
    vertical-align: top;
    background-color: #595b5b;
    box-shadow: inset 0 1px #737373;
    border: 1px solid #333434;
    border-radius: 4px;
  }

  .boton:hover {
    background-color: #626465;
  }

  .boton:focus {
    border: 1px solid #0036ff;
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.36), 0 0 0 2px #6fb5f1;
    outline: 0;
  }

  .boton:active {
    border: 1px solid #333434;
    background-color: #3f4041;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.05);
  }

  .boton-miniatura {
    color: #c6c8c8;
    background-color: #595b5b;
    border: 1px solid #333434;
    border-radius: 4px;
  }

  .boton-miniatura:hover {
    background-color: #626465;
  }

  .boton-miniatura-pulsado {
    border: 1px solid #333434;
    background-color: #3f4041;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.05);
  }

  .boton-miniatura-pulsable {
    color: #c6c8c8;
    background-color: #595b5b;
    border: 1px solid #333434;
    border-radius: 4px;
    vertical-align: bottom;
  }

  .boton-miniatura-pulsable:hover {
    background-color: #626465;
  }

  .boton-miniatura-pulsable:focus {
    border: 1px solid #0036ff;
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.36), 0 0 0 2px #6fb5f1;
    outline: 0;
  }

  .boton-miniatura-pulsable:active {
    border: 1px solid #333434;
    background-color: #3f4041;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.05);
  }

  .solapa-de-ayuda-seleccionada {
    opacity: 100%;
    color: #c6c8c8;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.69);
    vertical-align: top;
    background-color: #595b5b;
    box-shadow: inset 0 1px #737373;
    border: 1px solid #333434;
    border-bottom: 0;
    padding: 0.5em 1.25em;
    border-radius: 0;
    user-select: none;
  }

  .solapa-de-ayuda {
    opacity: 50%;
    color: #c6c8c8;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.69);
    vertical-align: top;
    border-bottom: 0;
    padding: 0.5em 1.25em;
    border-radius: 0;
    border: 1px solid transparent;
    user-select: none;
  }

  .solapa-seleccionada {
    opacity: 100%;
    color: #c6c8c8;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.69);
    vertical-align: top;
    background-color: #595b5b;
    box-shadow: inset 0 1px #737373;
    border: 1px solid #333434;
    padding: 0.5em;
    border-radius: 0;
    user-select: none;
  }

  .solapa {
    opacity: 50%;
    color: #c6c8c8;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.69);
    vertical-align: top;
    padding: 0.5em;
    border-radius: 0;
    border: 1px solid transparent;
    user-select: none;
  }

  .texto {
    color: #c6c8c8;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.69);
  }

  /* iconos */
  .carpeta,
  .carpeta-abierta,
  .crear-carpeta,
  .borrar,
  .imagen,
  .off,
  .on,
  .editar,
  .inactivo,
  .mover,
  .menu,
  .mas,
  .panel-abierto,
  .panel-cerrado,
  .duplicar {
    filter: invert(1);
  }

  .panel-izquierdo-on,
  .panel-central-on,
  .panel-derecho-on {
    filter: grayscale(1) brightness(2);
  }

  .b--invisible:hover {
    border-color: white;
  }

  .bg-skeleton {
    background-color: #444;
  }

  .borde {
    border-color: #313131;
  }

  .bg-fondo {
    background-color: #4a4d4e;
  }

  .bg-fondo-editor {
    background-color: #1e1e1e;
  }

  .ember-power-select-trigger {
    border: 1px solid #333434;
    background-color: #454646;
  }

  .ember-power-select-multiple-option {
    color: #c6c8c8;
    background: #3c3c3c;
    border: 1px solid #2a2a2a;
  }

  .ember-power-select-option[aria-current="true"] {
    background-color: #5897fb;
    color: #ffffff;
  }

  .ember-power-select-option[aria-selected="true"] {
    background-color: #212121;
    color: white;
  }

  .ember-power-select-option {
    background-color: #424242;
    color: #c6c8c8;
  }

  .ember-power-select-dropdown {
    border-color: #2c2c2c;
    border-radius: 0;
  }

  .ember-power-select-trigger-multiple-input {
    color: #c6c8c8;
  }

  .gutter.gutter-horizontal {
    border-left: 1px solid #313131;
  }

  .bg-fondo-secundario {
    background-color: #454646;
  }

  .bb-dashed-on-hover:hover {
    border-bottom: 1px dashed #c6c8c8;
  }

  .bb-dashed-on-hover {
    border-bottom: 1px solid transparent;
  }

  .bg-loader-del-panel-editor {
    background-color: #1e1e1e;
  }

}

.theme-oscuro.bg-fondo {
  background-color: #4a4d4e;
}

