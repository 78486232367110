.no-outline:focus {
  outline: none;
}

.disabled,
.no-pointer-events {
  pointer-events: none;
}

.usn {
  user-select: none;
}

.default-cursor {
  cursor: default;
}

.h2-5 {
  height: 3.4em;
}

.cursor-resize {
  cursor: col-resize;
}

.cursor-resize-on-hover:hover {
  cursor: col-resize;
}

.margin-auto {
  margin: auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

.mh-100 {
  max-height: 100%;
}

.h250 {
  height: 250px;
}

.text-shadow-1 {
  text-shadow: 0 0 10px black;
}

.h4-5 {
  height: 12em;
}

.w104 {
  width: 104px;
}

.w240 {
  width: 240px;
}

.b--invisible {
  border-color: rgba(1, 1, 1, 0);
}

.w25 {
  width: 3em;
}

.w-input-nombre-de-sensor {
  width: 116px;
}

.flex-expand {
  flex: 1;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.undraggable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.object-fit-contain {
  object-fit: contain;
}

.w-max-content {
  width: max-content;
}

.w180 {
  width: 180px;
}

.flex-inline {
  display: inline-flex;
}
